exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-components-faq-jsx": () => import("./../../../src/pages/about/components/FAQ.jsx" /* webpackChunkName: "component---src-pages-about-components-faq-jsx" */),
  "component---src-pages-about-components-inquiry-jsx": () => import("./../../../src/pages/about/components/Inquiry.jsx" /* webpackChunkName: "component---src-pages-about-components-inquiry-jsx" */),
  "component---src-pages-about-components-news-items-jsx": () => import("./../../../src/pages/about/components/NewsItems.jsx" /* webpackChunkName: "component---src-pages-about-components-news-items-jsx" */),
  "component---src-pages-about-components-press-jsx": () => import("./../../../src/pages/about/components/Press.jsx" /* webpackChunkName: "component---src-pages-about-components-press-jsx" */),
  "component---src-pages-about-components-team-jsx": () => import("./../../../src/pages/about/components/Team.jsx" /* webpackChunkName: "component---src-pages-about-components-team-jsx" */),
  "component---src-pages-about-components-team-members-jsx": () => import("./../../../src/pages/about/components/TeamMembers.jsx" /* webpackChunkName: "component---src-pages-about-components-team-members-jsx" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-emails-js": () => import("./../../../src/pages/emails.js" /* webpackChunkName: "component---src-pages-emails-js" */),
  "component---src-pages-entity-js": () => import("./../../../src/pages/entity.js" /* webpackChunkName: "component---src-pages-entity-js" */),
  "component---src-pages-home-components-chart-tabs-jsx": () => import("./../../../src/pages/home/components/ChartTabs.jsx" /* webpackChunkName: "component---src-pages-home-components-chart-tabs-jsx" */),
  "component---src-pages-home-components-racism-charts-jsx": () => import("./../../../src/pages/home/components/RacismCharts.jsx" /* webpackChunkName: "component---src-pages-home-components-racism-charts-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-manipulative-tactics-js": () => import("./../../../src/pages/manipulative-tactics.js" /* webpackChunkName: "component---src-pages-manipulative-tactics-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-sandbox-components-simple-bar-chart-jsx": () => import("./../../../src/pages/sandbox/components/SimpleBarChart.jsx" /* webpackChunkName: "component---src-pages-sandbox-components-simple-bar-chart-jsx" */),
  "component---src-pages-sandbox-components-simple-line-chart-jsx": () => import("./../../../src/pages/sandbox/components/SimpleLineChart.jsx" /* webpackChunkName: "component---src-pages-sandbox-components-simple-line-chart-jsx" */),
  "component---src-pages-sandbox-index-js": () => import("./../../../src/pages/sandbox/index.js" /* webpackChunkName: "component---src-pages-sandbox-index-js" */),
  "component---src-pages-senders-js": () => import("./../../../src/pages/senders.js" /* webpackChunkName: "component---src-pages-senders-js" */)
}

